// import api from '@/api'
// import notification from './notification'
import $ from 'jquery'
import Favico from 'favico.js'
import { store, GetterTypes } from '@/store'

const empty = (str: any): boolean => {
    if (typeof str === 'object' && str !== null) {
        return str.length === 0 || Object.keys(str).length === 0
    } else {
        return str === '' || str === null || str === undefined
    }
}

const inProduction = () => process.env.NODE_ENV === 'production'

const loadingScreen = (loading: boolean, message: any = '') => {
    let $box = $('body').find('#app-loading')
    if ($box.length === 0) {
        $box = $(`<div id="app-loading" class='loading-screen-box'>
        <div>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <div class="msg">${message}</div>
        </div>
        </div>`)
        $('body').append($box)
    } else {
        $box.find('.msg').html(message)
    }
    if (loading) {
        $box.css('display', 'flex').hide().fadeIn()
    } else {
        $box.hide()
    }
}

const applicationLock = (loading: boolean, message: any = '') => {
    let $box = $('body').find('#app-lock')
    if ($box.length === 0) {
        $box = $(`<div id="app-lock" style="background:#fff" class='loading-screen-box'>
        <div>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <div class="msg">${message}</div>
        </div>
        </div>`)
        $('body').append($box)
    }
    if (loading) {
        $box.css('display', 'flex').hide().fadeIn()
        $('body').addClass('body-lock')
    } else {
        $box.hide()
        $('body').removeClass('body-lock')
    }
}

const randomBetween = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min)

const pageTitleAlert = () => {
    new Favico({
        animation: 'popFade',
    }).badge(store.getters[GetterTypes.NOTIFICATION_SOLICITATION])
}

const urlIfy = (text: string) =>
    text.replace(/(https?:\/\/[^\s]+)/g, '<a target="_blank" href="$1">$1</a>')

export {
    empty,
    inProduction,
    applicationLock,
    loadingScreen,
    randomBetween,
    urlIfy,
    pageTitleAlert,
}
