interface IConfig {
    name: string
    version: string
}

const Config = {
    name: 'CAEMA.api',
    version: '1.0',
} as IConfig

export { Config }
