export enum MutationTypes {
    SET_TOKEN = 'SET_TOKEN',
    SET_CSRF_TOKEN = 'SET_CSRF_TOKEN',
    SET_USER = 'SET_USER',
    APPEND_ATTENDANCE = 'APPEND_ATTENDANCE',
    SET_ATTENDANCE = 'SET_ATTENDANCE',
    REMOVE_ATTENDANCE_BY_ID= 'REMOVE_ATTENDANCE_BY_ID',
    CHANGE_ATTENDANCE= 'CHANGE_ATTENDANCE',
    CLEAR_DATA = 'CLEAR_DATA',
    CLEAR_ATTENDANCE = 'CLEAR_ATTENDANCE',
    SET_NOTIFICATION_SERVER = 'SET_NOTIFICATION_SERVER',
    SET_NOTIFICATION_SOLICITATIONS = 'SET_NOTIFICATION_SOLICITATIONS',
    SET_NOTIFICATION_SOLICITATIONS_INCREMENT = 'SET_NOTIFICATION_SOLICITATIONS_INCREMENT',
    SET_NOTIFICATION_SOLICITATIONS_DECREMENT = 'SET_NOTIFICATION_SOLICITATIONS_DECREMENT',
}
