export enum Requests {
    CAEMA_REQUEST = 'frontend/caema_requests',
    CAEMA_REQUEST_ATTENDANCE = 'frontend/caema_requests_attendance',
    CAEMA_TREATED = 'frontend/caema_treated',
    DASHBOARD = 'frontend/dashboard'
}

export enum Users {
    GET_USER = 'frontend/user', //GET
    PING = 'frontend/ping',
    AUTH = 'frontend/auth',
    LIST_USERS = 'frontend/users',
    ADD_USER = 'frontend/user' //POST
}

