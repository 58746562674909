import { MutationTree } from 'vuex'
import { MutationTypes } from './mutation-types'
import User from '@/models/User'
import Notifications from '@/models/Notifications'
import IAttendance from '@/models/Attendance'
import { State } from './state'
import { TokenStorage } from './localStorage'
import { setBearerToken } from '@/api'
import { pageTitleAlert } from '@/utils'

interface IUpdateAttendance {
    id: number
    attendance: IAttendance
}

export type Mutations<S = State> = {
    [MutationTypes.SET_USER](state: S, user: User): void
    [MutationTypes.SET_TOKEN](state: S, payload: string): void
    [MutationTypes.SET_CSRF_TOKEN](state: S, payload: string): void
    [MutationTypes.CLEAR_DATA](state: S): void
    [MutationTypes.CLEAR_ATTENDANCE](state: S): void
    [MutationTypes.SET_NOTIFICATION_SERVER](state: S, payload: string): void
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS](
        state: S,
        total: number
    ): void
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT](
        state: S,
        value: number
    ): void
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT](
        state: S,
        value: number
    ): void
    [MutationTypes.SET_ATTENDANCE](state: S, data: Array<IAttendance>): void
    [MutationTypes.APPEND_ATTENDANCE](state: S, attendance: IAttendance): void
    [MutationTypes.REMOVE_ATTENDANCE_BY_ID](state: S, id: number): void
    [MutationTypes.CHANGE_ATTENDANCE](state: S, data: IUpdateAttendance): void
}

export const mutations: MutationTree<State> & Mutations & any = {
    [MutationTypes.SET_USER](state: State, user: User) {
        state.user = user
        state.notifications.solicitations = user.notifications?.solicitations
        state.notifications.sms = user.notifications?.sms
        state.notifications.email = user.notifications?.email

        pageTitleAlert()

    },
    [MutationTypes.SET_TOKEN](state: State, payload: string) {
        state.token = payload
        TokenStorage.setToken(payload)
        setBearerToken(payload)
    },
    [MutationTypes.SET_CSRF_TOKEN](state: State, payload: string) {
        state.csrf_token = payload
        const m = document.querySelector(
            "meta[name='csrf-token']"
        ) as HTMLMetaElement
        m.content = payload
    },
    [MutationTypes.CLEAR_DATA](state: State) {
        state.token = ''
        state.user = {} as User
        state.attendances = [] as Array<IAttendance>
        state.csrf_token = ''
        state.notifications = {
            sms: 0,
            email: 0,
            solicitations: 0
        } as Notifications
        TokenStorage.clear()
    },
    [MutationTypes.CLEAR_ATTENDANCE](state: State) {
        state.attendances = [] as Array<IAttendance>
    },
    [MutationTypes.SET_NOTIFICATION_SERVER](state: State, payload: string) {
        state.notification_server_id = payload
    },
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS](
        state: State,
        total: number
    ) {
        state.notifications.solicitations = total
        pageTitleAlert()
    },
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT](
        state: State,
        value: number
    ) {
        state.notifications.solicitations =
            (state.notifications.solicitations
                ? state.notifications.solicitations
                : 0) + value
                pageTitleAlert()
    },
    [MutationTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT](
        state: State,
        value: number
    ) {
        state.notifications.solicitations =
            (state.notifications.solicitations
                ? state.notifications.solicitations
                : 0) - value
                pageTitleAlert()
    },
    [MutationTypes.SET_ATTENDANCE](state: State, data: Array<IAttendance>) {
        state.attendances = data
    },
    [MutationTypes.APPEND_ATTENDANCE](state: State, attendance: IAttendance) {
        state.attendances.push(attendance)
    },
    [MutationTypes.REMOVE_ATTENDANCE_BY_ID](state: State, id: number) {
        state.attendances.splice(
            state.attendances.findIndex((item) => item.id === id),
            1
        )
    },
    [MutationTypes.CHANGE_ATTENDANCE](state: State, data: IUpdateAttendance) {
        state.attendances[
            state.attendances.findIndex(
                (item: IAttendance) => item.id === data.id
            )
        ] = data.attendance
    },
}
