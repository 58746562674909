import { EventObject } from '../interface'
import { store, ActionTypes, GetterTypes } from '@/store'
import IUser from '@/models/User'
import notification from '@/utils/notification'

export default {
    solicitation: function (data : any) {
        store.dispatch(ActionTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT, 1)
        notification
            .browser()
            .notify(
                `Nova solicitação de atendimento via ${data.platform}`,
                'info'
            )
    },
    treated: function (data : any) {
        const currentUser = store.getters[GetterTypes.USER] as IUser
        if (data.user_id === currentUser.id) {
            store.dispatch(ActionTypes.REMOVE_ATTENDANCE_BY_ID,data.attendance_id)
            store.dispatch(ActionTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT,1)
        }
    },
    owner: function (data : any) {
        const currentUser = store.getters[GetterTypes.USER] as IUser
        if (data.owner_user_id !== currentUser.id) {
            notification.toast("",`${data.owner_user_name} assumiu o contato ${data.attendance_name}`);
            store.dispatch(ActionTypes.REMOVE_ATTENDANCE_BY_ID,data.attendance_id)
            store.dispatch(ActionTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT,1)
        }
    }
} as EventObject
