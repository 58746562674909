import User from '@/models/User'
import IAttendance from '@/models/Attendance'
import Notification from '@/models/Notifications'

export const state = {
    token: '', //token da sessão ativa
    user: {} as User, //dados do usuário logado
    notification_server_id: '',
    csrf_token: '',
    attendances: [] as Array<IAttendance>,
    notifications: {
        email: 0,
        sms: 0,
        solicitations: 0
    } as Notification
}
export type State = typeof state
