import router from '@/router'
import { notification, loadingScreen } from '@/utils'

//Após receber uma solicitação
const onRequest = {
    onFulfilled: (response: any) => {
        return response
    },
    onRejected: (error: any) => {
        //Recebeu uma resposta do servidor.
        if (error.response) {
            const httpCodeError = error.response.status
            if (httpCodeError > 0) {
                const pathUrl = window.location.pathname
                const isPublic =
                    pathUrl.includes('login') || pathUrl.includes('logout')
                switch (httpCodeError) {
                    case 200:
                        loadingScreen(false)
                        break;
                    case 401:
                        if (!isPublic) {
                            notification.msgBoxOk("Sua sessão foi perdida","Acesso negado");
                            router.push('/login')
                        }
                        break
                    case 404:
                        //Página de destino não existe
                        router.push('/404')
                        break
                    case 403:
                        if (!isPublic) {
                            notification.toast("Acesso negado",error.response.data.message || 'Você não possui permissão para acessar esta página','danger')
                        }
                        break
                    case 405:
                        if (!isPublic) {
                            notification.msgBoxOk("Método ou URL não existem","ERRO")
                        }
                        break
                }

                return Promise.reject(error)
            }
        } else {
            notification.msgBoxOk(`O servidor respondeu com erro. Possíveis causas: Arquivo de upload muito grande, permissão de CORS negada, Sessão perdida.`,"ERRO")

        }
    },
}

export { onRequest }
