import { EventObject } from '../interface'
// import { store, GetterTypes } from '@/store'
import { notification, applicationLock } from '@/utils'
// const connectionNotificationId = 'connection_notification'

//Pede permissão para mostrar notificações
setTimeout(() => notification.browser().requestPermission(), 5000)

const setConnectionError = () => {
    applicationLock(
        true,
        'Conexão perdida! Tentando reconectar...'
    )
}

export default {
    connected: function () {
        applicationLock(false)
    },
    disconnected: function () {
        setConnectionError()
    },
    error: function () {
        setConnectionError()
    },
} as EventObject
