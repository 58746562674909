
















import Vue from 'vue'
import NavBar from './components/NavBar.vue'
import socket from '@/services/websocket/laravel-echo'
import { applicationLock } from '@/utils'
export default Vue.extend({
    components: { NavBar },
    data: () => ({
        show: false,
    }),
    computed: {
        privatePage(): boolean {
            return !['/login'].includes(this.$route.path)
        },
    },
    created() {
        //impede que o usuário veja o pré-processamento da página

        setTimeout(() => {
            this.show = true;

            if (this.privatePage) {
                //conexão websocket
                socket.run()
            } else {
                applicationLock(false)
            }
        }, 500)
    },
})
