// import { store } from '@/store'
import mp3soundalert from '@/assets/sounds/notify/done-for-you.mp3'
//icons
import iconSuccess from '@/assets/images/icons/notify/success.png'
import iconDanger from '@/assets/images/icons/notify/danger.png'
import iconInfo from '@/assets/images/icons/notify/info.png'
import iconDefault from '@/assets/images/icons/notify/default.png'
import iconAlert from '@/assets/images/icons/notify/alert.png'
//config
import { Config as app } from '@/models/Config'

//vue instance
import { vm } from '@/main'
import { empty } from '@/utils/functions'
import { BvMsgBoxOptions } from 'bootstrap-vue'

const defaultNotificationOptions = {
    size: 'sm',
    okVariant: 'primary',
    cancelVariant: 'default',
    okTitle: 'Sim',
    cancelTitle: 'Não',
    footerClass: 'p-2',
    hideHeaderClose: true,
    centered: true,
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
}

const notification = (function () {
    return {
        getBase(
            id: string,
            title: string,
            description = '',
            onClick = null,
            icon = ''
        ) {
            return {
                id: id,
                title: title,
                description: description,
                onClick: onClick,
                icon: icon,
                read: false,
            }
        },
        browser() {
            return {
                requestPermission() {
                    if ('Notification' in window) {
                        return Notification.requestPermission().then(() => {
                            switch (Notification.permission) {
                                // case 'granted':

                                //     break
                                case 'denied':
                                    notification.toast(
                                        'Erro de permissão',
                                        'Habilite as notificações'
                                    )
                                    // setTimeout(() => {
                                    //     store.dispatch(
                                    //         'app/ActionSetNotification',
                                    //         notification.getBase(
                                    //             'nbrowser',
                                    //             'Permissão Negada',
                                    //             `É importante que você permita as notificações deste sistema no seu navegador`,
                                    //             null,
                                    //             'pip'
                                    //         )
                                    //     )
                                    // }, 5000)
                                    break
                            }
                        })
                    }
                },
                notify(message: string, type = 'normal', timeout = 5000) {
                    if ('Notification' in window) {
                        let icon = iconDefault
                        notification
                            .browser()
                            .requestPermission()
                            ?.then(() => {
                                if (Notification.permission === 'granted') {
                                    setTimeout(() => {
                                        switch (type) {
                                            case 'success':
                                                icon = iconSuccess
                                                break
                                            case 'info':
                                                icon = iconInfo
                                                break
                                            case 'alert':
                                                icon = iconAlert
                                                break
                                            case 'danger':
                                            case 'error':
                                            case 'fail':
                                                icon = iconDanger
                                                break
                                        }
                                        const n = new Notification(
                                            app.name + ' - ' + app.version,
                                            {
                                                body: message,
                                                icon: icon,
                                            }
                                        )
                                        setTimeout(n.close.bind(n), timeout)
                                    }, 500)
                                }
                            })
                    }
                },
            }
        },
        toast(title: string, message: string, type = 'default', options = {}) {
            if (type === 'danger') type = 'error'
            const titleFormatter = empty(title)
                    ? ''
                    : `<div class="text-bold mb-2">${title}</div>`,
                messageBody = `${titleFormatter}${message}`,
                defaultOptions = {
                    message: messageBody,
                    type: type ?? 'default',
                    duration: 5000,
                },
                opt = Object.assign(true, defaultOptions, options)
            vm.$toast.open(opt)
        },
        toastTryCatchError(e): any {
            return new Promise((resolve, reject) => {
                try {
                    const response = e.response
                    const defaultMessageError = 'Erro desconhecido'
                    let defaultTitle = ''
                    let statusCode = 400
                    let data = {
                        message: defaultMessageError,
                        errors: {},
                    }
                    if (!empty(response)) {
                        data = response.data
                        statusCode = response.status
                    }

                    if (statusCode === 403) return false //Erro de permissão é tratado nos interceptors

                    if (statusCode === 422) {
                        //Erro de validação de formulário
                        if (data.message === 'The given data was invalid.')
                            data.message = 'Falha na validação dos dados'
                        defaultTitle = `<div class="text-bold mb-3">${data.message}</div>`
                        const errors = data.errors
                        data.message = Object.keys(errors)
                            .map((k) => `<div>• ${errors[k]}</div>`)
                            .join('')
                    }
                    vm.$toast.error(
                        defaultTitle + (data.message || defaultMessageError),
                    )
                    resolve({
                        validation: data,
                        fields: Object.keys(data.errors || {}).map((k) => k),
                    })
                } catch (err) {
                    reject(err)
                }
            })
        },
        sound() {
            return {
                play() {
                    const audio = new Audio(mp3soundalert)
                    return audio.play()
                },
            }
        },
        msgBoxConfirm(
            title: string,
            message: string | Array<any>,
            opt: BvMsgBoxOptions
        ) {
            let dataMessage = message
            const dataIcons = ['success', 'danger', 'error', 'info', 'help']
            if (dataIcons.includes(title)) {
                opt.hideHeader = true
                const h = vm.$createElement
                let iconName = 'b-icon-patch-check'
                let iconClass = ['text-success']

                switch (title) {
                    case 'error':
                        iconName = 'b-icon-patch-minus'
                        iconClass = ['text-danger']
                        break
                    case 'info':
                        iconName = 'b-icon-info-circle-fill'
                        iconClass = ['text-info']
                        break
                    case 'danger':
                        iconName = 'b-icon-patch-exclamation'
                        iconClass = ['text-danger']
                        break
                    case 'help':
                        iconName = 'b-icon-patch-question'
                        iconClass = ['text-info']
                        break
                }

                const titleIcon = h(iconName, {
                    class: iconClass,
                    props: {
                        fontScale: '5',
                    },
                })
                const boxContent = h(
                    'div',
                    {
                        class: 'text-center mb-3',
                    },
                    [titleIcon]
                )

                dataMessage = [boxContent, message]
            }

            const options = Object.assign(defaultNotificationOptions, opt)
            return vm.$bvModal.msgBoxConfirm(dataMessage, options)
        },
        msgBoxConfirmDel(message: string) {
            return notification.msgBoxConfirm('danger', message, {
                okVariant: 'danger',
            })
        },
        inputBox(title: string, message: any, opt: BvMsgBoxOptions = {}) {
            opt.title = title
            const options = Object.assign(defaultNotificationOptions, opt)
            const h = vm.$createElement

            //return vm.$bvModal.msgBoxConfirm([messageVNode], options)
            return new Promise((resolve, reject) => {
                const formInput = h('b-form-input', {
                    props: {
                        type: opt.input?.type || 'text',
                        placeholder: opt.input?.placeholder || '',
                        autocomplete: 'off',
                    },
                })

                const form = h(
                    'b-form',
                    {
                        props: {
                            autocomplete: 'off',
                        },
                        on: {
                            submit: function (e: InputEvent) {
                                e.preventDefault()
                            },
                        },
                    },
                    [formInput]
                )
                const elementInput = h(
                    'p',
                    { class: ['text-center', 'p-0', 'm-0'] },
                    [form]
                )
                const messageVNode = h('div', { class: ['foobar'] }, [
                    h('p', { class: ['text-left'] }, [message]),
                    elementInput,
                ])

                vm.$bvModal
                    .msgBoxConfirm([messageVNode], options)
                    .then((success) => {
                        if (success) {
                            resolve(formInput.elm?.nodeValue || '')
                        } else {
                            reject(success)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        msgBoxOk(title: string, message: any, opt: BvMsgBoxOptions = {}) {
            opt.title = title
            const options = Object.assign(defaultNotificationOptions, opt)
            return vm.$bvModal.msgBoxOk(message, options)
        },
    }
})()

export default notification
