import address from '../../api/address'
import { store, GetterTypes } from '../../store'

import Vue from 'vue'
import Pusher from 'pusher-js'
import VueEcho from 'vue-echo'
import Echo from 'laravel-echo'
import { events } from './index'
import { inProduction } from '@/utils/functions'
declare global {
    interface Window {
        Pusher: any
    }
}
window.Pusher = Pusher

// window.io = require("socket.io-client");

export default {
    run: () => {
        const token = store.getters[GetterTypes.TOKEN]

        //window.Pusher = Pusher
        const prod = inProduction()

        //instância de Echo
        const EchoInstance = new Echo({
            broadcaster: 'pusher',
            key: 'caema16002',
            wsPort: 6002,
            wssPort: 6002,
            encrypted: prod,
            enabledTransports: ['ws'],
            wsHost: address.baseAppWS,
            transports: ['websocket'], // Fix CORS error!
            disableStats: false,
            forceTLS: prod,
            authEndpoint: address.baseAppWS + '/auth/socket',
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        })

        Vue.use(VueEcho, EchoInstance)

        // monitoramento global da aplicação
        Object.keys(events.channel).forEach((channelKey: string) => {
            const eventData = events.channel[channelKey]
            Object.keys(eventData).forEach((evName) => {
                EchoInstance.channel(channelKey).listen(
                    `.${evName}`,
                    eventData[evName]
                )
            })
        })

        //eventos de conexão
        Object.keys(events.connection).forEach((eventKey) => {
            const eventData = events.connection[eventKey]
            Object.keys(eventData).forEach((evName) =>
                EchoInstance.connector.pusher.connection.bind(
                    evName,
                    eventData[evName]
                )
            )
        })
    },
}
