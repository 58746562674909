


































import Vue from 'vue'
import imageLogo from '@/assets/images/logo.svg'
import { GetterTypes } from '@/store'
import User from '@/models/User'
// import { SlideYDownTransition } from 'vue2-transitions'

export default Vue.extend({
    name: 'NavBar',
    // components: { SlideYDownTransition },
    computed: {
        getLogo(): string {
            return imageLogo
        },
        getUser(): User {
            return this.$store.getters[GetterTypes.USER] as User
        },
        getNotifications() {
            return {
                solicitations: this.$store.getters[
                    GetterTypes.NOTIFICATION_SOLICITATION
                ],
            }
        },
    }
})
