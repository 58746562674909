import axios from 'axios'
import * as routers from './router'
import { onRequest } from './interceptors'
import address from './address'

const api = axios.create({
    baseURL: address.baseAppApi,
    timeout: 30000,
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    },
})

api.interceptors.response.use(onRequest.onFulfilled, onRequest.onRejected)
const setBearerToken = (token: string) =>
    (api.defaults.headers.common['Authorization'] = `Bearer ${token}`)

export default api
export { routers, setBearerToken, address }
