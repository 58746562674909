import { store } from '@/store'
import Vue from 'vue'
import { Route, NavigationGuardNext } from 'vue-router'
import { ActionTypes, MutationTypes } from '@/store/types'
import { pageTitleAlert, loadingScreen } from '@/utils'
import api, { routers } from '@/api'

const loginPath = '/login'
const DEFAULT_TITLE = 'CAEMA.api'

const beforeEnter = async (
    to: Route,
    from: Route,
    next: NavigationGuardNext
) => {
    //ignore
    if (to.path !== loginPath && to.path !== '/') {
        try {
            loadingScreen(true, '')
            const {
                data: { data },
            } = await api.get(routers.Users.PING, {
                params: {
                    path: to.path,
                },
            })

            next()
        } catch (err) {
            console.log(err)
        } finally {
            loadingScreen(false)
        }
    } else {
        next()
    }
}

const beforeEach = async (
    to: Route,
    from: Route,
    next: NavigationGuardNext
) => {
    if (to.path !== loginPath) {
        store.dispatch(ActionTypes.HAS_AUTHENTICATED).catch(() => {
            next({ path: loginPath })
            return
        })
    } else {
        store.commit(MutationTypes.CLEAR_DATA)
    }
    next()
}

const afterEach = async (to: Route, from: Route) => {
    //ignore
    window.scrollTo(0, 0)
    Vue.nextTick(() => {
        document.title = to.meta.title
            ? `${DEFAULT_TITLE} | ${to.meta.title}`
            : DEFAULT_TITLE
        pageTitleAlert()
    })
    loadingScreen(false)
}

export { beforeEach, afterEach, beforeEnter }
