import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import api from './api'
import STemplate from '@/components/STemplate.vue'
import VueToast from 'vue-toast-notification'

import "@/filters"

//Bootstrap
import { BootstrapVue, IconsPlugin, OverlayPlugin } from 'bootstrap-vue'
import './assets/scss/_app.scss'

//setting
Vue.config.productionTip = false

//use
Vue
.use(BootstrapVue)
.use(IconsPlugin)
.use(OverlayPlugin)
.use(VueToast)
.use(require('vue-moment'))

//global components
Vue.component('s-template', STemplate)

//prototypes
Vue.prototype.$api = api

export const vm = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

export default vm
