export default [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import('@/views/About.vue'),
    },
    {
        path: '/solicitacoes',
        name: 'Solicitacoes',
        meta: {
            title: 'Solicitações'
        },
        component: () =>
            import('@/views/Solicitacoes.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/access/Login.vue'),
    },
    {
        path: '/config',
        name: 'Configurações',
        meta: {
            title: 'Configurações'
        },
        redirect: '/config/usuarios',
        component: () => import('@/views/config/Config.vue'),
        children: [
            {
              path: "usuarios",
              name: "Configuração de Usuários",
              components: {
                  config: () => import('@/views/config/User.vue')
              },
            }
          ]
    },
    {
        path: '*',
        name: 'Erro 404',
        meta: {
            title: 'Página não existe'
        },
        component: () => import('@/views/errors/404.vue'),
    },
]
