const keys = {
    token: 'app_public_token'
}
const TokenStorage = {
    setToken(token: string): string {
        localStorage.setItem(keys.token, token)
        return this.getToken()
    },
    getToken(): string {
        return localStorage.getItem(keys.token) || ''
    },
    clear(){
        localStorage.removeItem(keys.token)
    }
}

export { TokenStorage }
