import { ActionTree, ActionContext } from 'vuex'
import { State } from './state'
import { Mutations } from './mutations'
import { ActionTypes } from './action-types'
import { MutationTypes } from './mutation-types'
import { GetterTypes } from './getter-types'
import User from '@/models/User'
import IAttendance from '@/models/Attendance'
import { TokenStorage } from '@/store/localStorage'
import api, { routers } from '@/api'
import { loadingScreen } from '@/utils'

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
    [ActionTypes.SET_TOKEN](
        { commit }: AugmentedActionContext,
        payload: string
    ): Promise<string>
    [ActionTypes.SET_CSRF_TOKEN](
        { commit }: AugmentedActionContext,
        payload: string
    ): Promise<string>
    [ActionTypes.SET_USER](
        { commit }: AugmentedActionContext,
        payload: User
    ): Promise<User>
    [ActionTypes.HAS_AUTHENTICATED]({
        getters,
        commit,
    }: AugmentedActionContext): Promise<boolean>
    [ActionTypes.SET_NOTIFICATION_SERVER](
        { commit }: AugmentedActionContext,
        payload: string
    ): Promise<boolean>
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS](
        { commit }: AugmentedActionContext,
        total: number
    ): Promise<boolean>
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT](
        { commit }: AugmentedActionContext,
        value: number
    ): Promise<boolean>
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT](
        { commit }: AugmentedActionContext,
        value: number
    ): Promise<boolean>
    [ActionTypes.SET_ATTENDANCE](
        { commit }: AugmentedActionContext,
        data: Array<IAttendance>
    ): Promise<boolean>
    [ActionTypes.APPEND_ATTENDANCE](
        { commit }: AugmentedActionContext,
        attendance: IAttendance
    ): Promise<boolean>
    [ActionTypes.REMOVE_ATTENDANCE_BY_ID](
        { commit }: AugmentedActionContext,
        id: number
    ): Promise<boolean>
}

export const actions: ActionTree<State, State> & Actions = {
    [ActionTypes.SET_TOKEN]({ commit }, token: string) {
        return new Promise((resolve) => {
            commit(MutationTypes.SET_TOKEN, token)
            resolve(token)
        })
    },
    [ActionTypes.SET_CSRF_TOKEN]({ commit }, token: string) {
        return new Promise((resolve) => {
            commit(MutationTypes.SET_CSRF_TOKEN, token)
            resolve(token)
        })
    },
    [ActionTypes.SET_USER]({ commit }, user: User) {
        return new Promise((resolve) => {
            console.log(user)
            commit(MutationTypes.SET_USER, user)
            resolve(user)
        })
    },
    [ActionTypes.HAS_AUTHENTICATED]({ getters, commit }) {
        return new Promise((resolve, reject) => {
            try {
                const storeToken = getters[GetterTypes.TOKEN]

                if (!storeToken) {
                    const localStorageToken = TokenStorage.getToken()
                    if (!localStorageToken) {
                        //redirect to login
                        throw false
                    } else {
                        //atualiza o token no store, provavelmente o usuário atualizou a página
                        commit(MutationTypes.SET_TOKEN, localStorageToken)
                    }
                }

                //verifica se o usuário existe
                const storeUser = getters[GetterTypes.USER] as User
                if (!storeUser.email) {
                    loadingScreen(true, 'Carregando componentes...')

                    api.get(routers.Users.GET_USER)
                        .then(({ data: { data } }) => {
                            commit(MutationTypes.SET_USER, data.user)
                        })
                        .catch(() => {
                            throw false
                        })
                        .finally(() => loadingScreen(false))
                } else {
                    resolve(true)
                }

                //verifica no backend se a sessão ainda existe
            } catch (err) {
                reject(false)
            }
        })
    },
    [ActionTypes.SET_NOTIFICATION_SERVER]({ commit }, key: string) {
        return new Promise((resolve, reject) => {
            if (key === null || key === '') {
                reject('key is empty')
                return false
            }
            commit(MutationTypes.SET_NOTIFICATION_SERVER, key)
            resolve(true)
        })
    },
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS]({ commit }, total: number) {
        return new Promise((resolve) => {
            if (total == null) total = 0
            commit(MutationTypes.SET_NOTIFICATION_SOLICITATIONS, total)
            resolve(true)
        })
    },
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT]({ commit }, value: number) {
        return new Promise((resolve) => {
            if (value == null) value = 0
            commit(MutationTypes.SET_NOTIFICATION_SOLICITATIONS_INCREMENT, value)
            resolve(true)
        })
    },
    [ActionTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT]({ commit }, value: number) {
        return new Promise((resolve) => {
            if (value == null) value = 0
            commit(MutationTypes.SET_NOTIFICATION_SOLICITATIONS_DECREMENT, value)
            resolve(true)
        })
    },
    [ActionTypes.APPEND_ATTENDANCE]({ commit }, attendance: IAttendance) {
        return new Promise((resolve) => {
            commit(MutationTypes.APPEND_ATTENDANCE,attendance)
            resolve(true)
        })
    },
    [ActionTypes.SET_ATTENDANCE]({ commit }, data: Array<IAttendance>) {
        return new Promise((resolve) => {
            commit(MutationTypes.SET_ATTENDANCE,data)
            resolve(true)
        })
    },
    [ActionTypes.REMOVE_ATTENDANCE_BY_ID]({ commit }, id: number) {
        return new Promise((resolve) => {
            commit(MutationTypes.REMOVE_ATTENDANCE_BY_ID,id)
            resolve(true)
        })
    }
}
