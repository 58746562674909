import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { beforeEach, afterEach, beforeEnter } from './setting'
import pages from './pages'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = pages

const router = new VueRouter({
    mode: 'history',
    routes,
    linkExactActiveClass: 'router-link-active',
    base: '/',
})
router.beforeEach(beforeEach)
router.afterEach(afterEach)
router.beforeResolve(beforeEnter)

export default router
