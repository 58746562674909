import { GetterTree } from 'vuex'
import { State } from './state'
import User from '@/models/User'
import IAttendance from '@/models/Attendance'
import { GetterTypes } from './types'

export type Getters = {
    [GetterTypes.USER](state: State): User
    [GetterTypes.TOKEN](state: State): string
    [GetterTypes.NOTIFICATION_SERVER_CHECK](state: State): any
    [GetterTypes.NOTIFICATION_SOLICITATION](state: State): number
    [GetterTypes.ATTENDANCES](state: State): Array<IAttendance>
}

export const getters: GetterTree<State, State> & Getters = {
    [GetterTypes.USER]: (state) => state.user,
    [GetterTypes.TOKEN]: (state) => state.token,
    [GetterTypes.NOTIFICATION_SERVER_CHECK]: function (state) {
        return (key: string) => {
            return state.notification_server_id === key
        }
    },
    [GetterTypes.NOTIFICATION_SOLICITATION]: (state) => state.notifications.solicitations || 0,
    [GetterTypes.ATTENDANCES]: function (state) {
        return state.attendances
    },
}
