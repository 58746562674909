export enum ActionTypes {
    SET_USER = 'SET_USER',
    SET_TOKEN = 'SET_TOKEN',
    SET_CSRF_TOKEN = 'SET_CSRF_TOKEN',
    APPEND_ATTENDANCE = 'APPEND_ATTENDANCE',
    SET_ATTENDANCE = 'SET_ATTENDANCE',
    REMOVE_ATTENDANCE_BY_ID = 'REMOVE_ATTENDANCE_BY_ID',
    HAS_AUTHENTICATED = 'HAS_AUTHENTICATED',
    CLEAR_DATA = 'CLEAR_DATA',
    SET_NOTIFICATION_SERVER = 'SET_NOTIFICATION_SERVER',
    SET_NOTIFICATION_SOLICITATIONS = 'SET_NOTIFICATION_SOLICITATIONS',
    SET_NOTIFICATION_SOLICITATIONS_INCREMENT = 'SET_NOTIFICATION_SOLICITATIONS_INCREMENT',
    SET_NOTIFICATION_SOLICITATIONS_DECREMENT = 'SET_NOTIFICATION_SOLICITATIONS_DECREMENT',
}
